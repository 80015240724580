const state = {
    infoPanelShowing: false,
    statsPanelShowing: false,
    showDebugMenu: false,
}

const mutations = {
    updateToggleInfoPanelVisibility(state, toggle) {
        state.infoPanelShowing = toggle;
    },

    updateToggleStatsPanelVisibility(state, toggle) {
        state.statsPanelShowing = toggle;
    },

    updateToggleDebugMenuVisibility(state, toggle) {
        state.showDebugMenu = toggle;
    },
}

const actions = {
    toggleInfoPanel: ({commit}, toggle) => {
        commit('updateToggleInfoPanelVisibility', toggle);
    },

    toggleStatsPanel: ({commit}, toggle) => {
        commit('updateToggleStatsPanelVisibility', toggle);
    },

    toggleDebugMenu: ({commit}, toggle) => {
        commit('updateToggleDebugMenuVisibility', toggle);
    }, 

    toggleDebugMenuOn: ({commit}) => {
        commit('updateToggleDebugMenuVisibility', true);
    }, 
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}