<template>
  <div>
      <button class="btn share-guess" :class="{ 'has-won': isWin}" @click="generateShareString()" aria-label="Share your results">
          🔗 Share
      </button>
  </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { daysSinceStart } from '../lib/helpers.js';
    import { guessRating, guessEmojiRating } from '../lib/guess.js';

    export default {
        computed: {
            ...mapGetters('GameManager', [
                'getGuessCount',
                'isWin',
                'getGuessWinIndex',
                'allowedGuessCount',
                'getHighestPercentage',
                'isWin',
                'getRemainingGuesses',
            ]),

            ...mapState('GameManager', [
                'allowedGuessCount',
                'gameMode',
                'guesses',
                'seedText',
            ])
        },

        methods: {
            generateShareString() {
                const gameModeText = (this.gameMode === 'seed' ? `#${this.seedText}` : daysSinceStart())

                // Create share emoji boxes
                const emojiGuesses = this.guesses.map(guess => {
                    const rating = guessRating(guess.percentage);
                    return guessEmojiRating(rating);
                }).join('');

                let remainingGuessStr = '';

                for (let i = 0; i < this.getRemainingGuesses; i++) {
                    remainingGuessStr += '⬜️';
                }

                const winStr = this.isWin ? this.getGuessWinIndex + 1 : 'X';

                const shareStr = `#Flaggle ${gameModeText} ${winStr}/${this.allowedGuessCount} (${this.getHighestPercentage}%)\n🚩 ${emojiGuesses}${remainingGuessStr}`;

                try {
                    navigator.clipboard.writeText(shareStr);
                } catch($e) {
                }
            },
        }
    }
</script>