var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn share-guess",
        class: { "has-won": _vm.isWin },
        attrs: { "aria-label": "Share your results" },
        on: {
          click: function ($event) {
            return _vm.generateShareString()
          },
        },
      },
      [_vm._v("\n        🔗 Share\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }