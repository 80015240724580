<template>
  <div>
        <template v-if="giveUp || isWin || isOutOfGuesses">
            <Share />
            <p style="text-align: center;">
                <a v-if="this.answer.name" class="flag-wiki-link" :href="wikipediaCountryLink" target="_blank">{{answer.name }} flag on Wikipedia</a>
            </p>
        </template>
      <template v-else>
        <vue-autosuggest
                v-model="query"
                :suggestions="filteredOptions"            
                :input-props="inputProps"
                :should-render-suggestions="shouldRenderSuggestions"
                @focus="focusInput"
                @selected="onSelected"
                @input="onInputChange"
                ref="autocomplete">  
                <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{suggestion.item.name }}</span>
                </template>

                <template slot="after-suggestions">
                    <div class="autosuggest__results--none" v-if="filteredOptions[0].data.length == 0">
                        No results
                    </div>
                </template>
            </vue-autosuggest>

            <div class="button-actions">
                <button class="btn submit-guess" @click="submitGuess()" aria-label="Submit your guess">🌐 Guess</button>
                <button class="btn give-up" @click="submitGiveUp()" aria-label="Give up">🏳️</button>
            </div>
      </template>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import { locationData } from '../lib/locations';
import { getTodaysDateString } from '../lib/helpers';
import { mapActions, mapGetters, mapState } from 'vuex';

import Guess from '../lib/guess';
import Share from './Share.vue';

export default {
    data: () => ({
        query: '',
        userInput: '',
        suggestions: locationData,
        inputProps: {
            id: "autosuggest__input",
            placeholder: "Country"
        },
        countrySelected: false,
    }),

    components: {
        VueAutosuggest,
        Share,
    },

    computed: {
        ...mapGetters('GameManager', [
            'isOutOfGuesses',
            'isWin',
            'getCountryObjByNameHoisted',
        ]),

        ...mapState('GameManager', [
            'answer',
            'giveUp',
            'gameMode',
            'seedText',
        ]),

        filteredOptions() {
            return [
                { 
                    data: this.suggestions.filter(option => {
                        if (this.query == null) return locationData;
                        else return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
                    })
                }
            ];
        },

        wikipediaCountryLink() {
            return `https://en.wikipedia.org/wiki/Flag_of_${this.answer.name}`;
        },
    },
    methods: {
        ...mapActions('GameManager', [
            'addNewGuess',
            'setGiveUpGame',
        ]),

        ...mapActions('StatsManager', [
            'addGuessToHistory',
            'updateStatsBasedOnHistory',
        ]),

        focusInput() {
            window.scrollTo(0,0);
        },
     
        onSelected(val) {
            if (val && val.item != null) {
                this.userInput = val.item.name;
                this.countrySelected = true;
            }

            if (this.countrySelected) {
                this.submitGuess();
            }
        },
        onInputChange(val) {
            this.countrySelected = false;
        },
        shouldRenderSuggestions (size, loading) {
            return size >= 0 && !loading && this.query && this.query.length > 0;
        },
        submitGuess() { 
            if (this.userInput && this.userInput != '') {
                const foundCountry = this.getCountryObjByNameHoisted(this.userInput);
                const newGuess = new Guess({
                    name: foundCountry.name,
                    location: foundCountry,
                    answer: this.answer,
                    date: getTodaysDateString(),
                    gameMode: this.gameMode,
                    seedText: this.seedText,
                });

                this.addNewGuess(newGuess);
                this.addGuessToHistory(newGuess);
                this.updateStatsBasedOnHistory();

                this.userInput = '';
                this.$refs.autocomplete.searchInput = null;
                this.$refs.autocomplete.internalValue = null;
                this.query = null;
            }
        },

        submitGiveUp() {
            this.setGiveUpGame(true);
        },
    },
    
}
</script>

<style lang="scss" scoped>
    .button-actions {
        display: flex;
        justify-content: space-between;
    }
</style>

<style lang="scss">
    input {
        width: 100%;
        border: 3px solid grey;
        padding: 3px 10px;
    }
    
    .flag-wiki-link {
        color: #898989;
        font-size: 13px;
    }

    .btn {
        width: 100%;
        padding: 7px 10px;
        text-align: center;
        background: transparent;
        border-radius: 3px;
        border: 3px solid grey;
        appearance: none;
        color: white;
        margin-bottom: 5px;
        font-weight: bold;
        cursor: pointer;
        will-change: background-color, border-color;
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

        &:hover {
            background-color: #644c85;
            border-color: #5c2aa1;
        }

        &:active {
            background-color: #5c2aa1;
            border-color: #5c2aa1;
        }

        &.has-won {
            background-color: #1f7c34;
            border-color: #1f7c34;
        }

        &.give-up {
            width: auto;
            margin-left: 10px;
        }
    }

    #autosuggest {
        position: relative;
    }
    
    #autosuggest__input {
        caret-color: #ddd;
        position: relative;
        display: block;
        font-family: monospace;
        border-radius: 3px;
        box-sizing: border-box;
        outline: none;
        position: relative;
        display: block;
        font-family: monospace;
        font-size: 16px;
        border: 1px solid #616161;
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
        background-color: #1b1622;       
        color: #828282;

        &:focus-visible {
            outline: 2px solid #ba8af5;
            border-color: transparent !important;
        }
    }
    
    #autosuggest__input.autosuggest__input--open,
    #autosuggest__input:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid lightgray;
    }
    
    .autosuggest__results-container {
      width: 100%;
    }
    
    .autosuggest__results {
        font-weight: 300;
        margin: 0;
        position: absolute;
        z-index: 10000001;
        width: 100%;
        border: 1px solid #808080;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #1b1622;
        padding: 0px;
        overflow: auto;
        max-height: 200px;

        &--none {
            color: white;
            font-size: 16px;
            padding: 5px 10px;
        }

        @media (max-width: 767px) {
            bottom: 100%;
            top: auto;
            margin-bottom: 8px;
            max-height: 180px;
        }
    }
    
    .autosuggest__results ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

    .autosuggest__results-item {
        cursor: pointer;
        padding: 5px 10px;
        color: white;
        font-size: 16px;

        @media (min-width: 768px) {
         font-size: 14px;
        }

        &--highlighted {
            background-color: #5c2aa1;
        }
    }
    
    #autosuggest ul:nth-child(1) > .autosuggest__results_title {
      border-top: none;
    }
    
    .autosuggest__results .autosuggest__results_title {
      color: gray;
      font-size: 11px;
      margin-left: 0;
      padding: 15px 13px 5px;
      border-top: 1px solid lightgray;
    }
    
    .autosuggest__results .autosuggest__results_item:active,
    .autosuggest__results .autosuggest__results_item:hover,
    .autosuggest__results .autosuggest__results_item:focus,
    .autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
      background-color: #ddd;
    }

    .autosuggest__results {
        --sb-track-color: #232E33;
        --sb-thumb-color: #bb89f1;
        --sb-size: 9px;
    }

    .autosuggest__results::-webkit-scrollbar {
        width: var(--sb-size);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .autosuggest__results::-webkit-scrollbar-track {
        background: var(--sb-track-color);
        border-radius: 4px;
    }

    .autosuggest__results::-webkit-scrollbar-thumb {
        background: var(--sb-thumb-color);
        border-radius: 4px;
    }

    @supports not selector(::-webkit-scrollbar) {
        .autosuggest__results {
            scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
        }
    }
</style>