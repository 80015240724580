<template>
    <div class="menu">
        <strong>Debug Menu</strong>
        <p style="margin-bottom: 1rem"> 
            GameMode: {{gameMode}}<br>
            Guess Count: {{getGuessCount}}<br>
            Remaining Guesses: {{ allowedGuessCount - getGuessCount}}<br>
            Given Up: {{ giveUp}}<br>

            <label>
                Hardcore mode <input style="width: auto;" type="checkbox" @change="updateHardcoreMode()">
            </label>
        </p>

        <button class="btn" @click="setAnswerRandomLocation()">New Location</button>
        <button class="btn" @click="clearAllGuesses()">Clear guesses</button>
        <button class="btn" @click="regenGuesses()">Regen guesses</button>
        <button class="btn" @click="clearStorage()">Clear Storage</button>
        <br>
        <pre class="answer">Location: {{ answer }}</pre>
        <label>Force Country:</label>
        <input type="text" v-model="forceCountryName">
        <button class="btn" @click="forceCountry()">Force Country</button>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex';
    import { clearGuessesData } from '../lib/guess';

    export default {
        data() {
            return {
                forceCountryName: null,
            }
        },
         computed: {
            ...mapGetters('GameManager', [
                'getGuessCount',
            ]),
            ...mapState('GameManager', [
                'answer',
                'allowedGuessCount',
                'gameMode',
                'giveUp',
            ]),
         },

         methods: {
            ...mapActions('GameManager', [
                'setAnswerRandomLocation',
                'clearAllGuesses',
                'regenGuesses',
                'setHardcoreMode',
                'setAnswerLocationByName',
            ]),

            updateHardcoreMode() {
                this.setHardcoreMode();
            },

            forceCountry() {
                this.setAnswerLocationByName(this.forceCountryName);
            },

            clearStorage() {
                clearGuessesData();
            }
        }
    }
</script>

<style lang="scss">
    .menu {
        border: 1px solid white;
        border-radius: 10px;
        padding: 15px;
        margin-top: 10px;
        color: white;

        .btn {
            width: auto;
        }
    }
</style>