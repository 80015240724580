var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "modal__header" }, [
        _c("h2", [_vm._v("Information")]),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { "aria-label": "Close modal" },
            on: {
              click: function ($event) {
                return _vm.toggleInfoPanel(false)
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 512 512",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The aim is to guess the name of the country of the shown flag in as little guesses as possible. Each guess will display how accurate you are by displaying the distance away in km as well as a proximity percentage, the if you are on the other side of the world it will show as 0%."
        ),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Scoring")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h3", [_vm._v("Seeds")]),
      _vm._v(" "),
      _c("p", [_vm._v("The game words off a seeding functionality")]),
      _vm._v(" "),
      _c("h3", [_vm._v("Shuffle")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Want more Flaggle? Use the shuffle mode to play randomised games. The seed value is shown if you want your friends to give that flag a go, click the share seed to add a link to your clipboard!"
        ),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Inspiration")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h3", [_vm._v("Thanks")]),
      _vm._v(" "),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("The score is calculated into four bands.\n            "),
      _c("ul", { staticClass: "plain" }, [
        _c("li", [
          _vm._v("\n                    🟩 "),
          _c("strong", [_vm._v("Excellent")]),
          _vm._v(
            " - shown if the guess is 85% closer or more.\n                "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n                    🟨 "),
          _c("strong", [_vm._v("Great")]),
          _vm._v(
            " - shown if the guess is between 50% and 85% closer or more.\n                "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n                    🟧 "),
          _c("strong", [_vm._v("Good")]),
          _vm._v(
            " - shown if the guess is between 20% and 49% closer or more.\n                "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n                    🟥 "),
          _c("strong", [_vm._v("Poor")]),
          _vm._v(
            " - keep practicing! This is shown if you are lower than 20%.\n                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Heavily inspired by the excellent "),
      _c(
        "a",
        { attrs: { href: "https://worldle.teuteuf.fr/", target: "_blank" } },
        [_vm._v("Worldle")]
      ),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Hope you find this enjoyable, thanks for playing"),
      _c("br"),
      _vm._v("\n        Created with by John."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }