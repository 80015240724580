var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.giveUp || _vm.isWin || _vm.isOutOfGuesses
        ? [
            _c("Share"),
            _vm._v(" "),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              this.answer.name
                ? _c(
                    "a",
                    {
                      staticClass: "flag-wiki-link",
                      attrs: {
                        href: _vm.wikipediaCountryLink,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.answer.name) + " flag on Wikipedia")]
                  )
                : _vm._e(),
            ]),
          ]
        : [
            _c(
              "vue-autosuggest",
              {
                ref: "autocomplete",
                attrs: {
                  suggestions: _vm.filteredOptions,
                  "input-props": _vm.inputProps,
                  "should-render-suggestions": _vm.shouldRenderSuggestions,
                },
                on: {
                  focus: _vm.focusInput,
                  selected: _vm.onSelected,
                  input: _vm.onInputChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ suggestion }) {
                      return [
                        _c("span", { staticClass: "my-suggestion-item" }, [
                          _vm._v(_vm._s(suggestion.item.name)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              },
              [
                _vm._v(" "),
                _c("template", { slot: "after-suggestions" }, [
                  _vm.filteredOptions[0].data.length == 0
                    ? _c("div", { staticClass: "autosuggest__results--none" }, [
                        _vm._v(
                          "\n                      No results\n                  "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "button-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn submit-guess",
                  attrs: { "aria-label": "Submit your guess" },
                  on: {
                    click: function ($event) {
                      return _vm.submitGuess()
                    },
                  },
                },
                [_vm._v("🌐 Guess")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn give-up",
                  attrs: { "aria-label": "Give up" },
                  on: {
                    click: function ($event) {
                      return _vm.submitGiveUp()
                    },
                  },
                },
                [_vm._v("🏳️")]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }