<template>
    <div class="modal">
        <div class="container">
            <div class="modal__header">
                <h2>Information</h2>
                <button @click="toggleInfoPanel(false)" aria-label="Close modal">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                </button>
            </div>
            <p>The aim is to guess the name of the country of the shown flag in as little guesses as possible. Each guess will display how accurate you are by displaying the distance away in km as well as a proximity percentage, the if you are on the other side of the world it will show as 0%.</p>

            <h3>Scoring</h3>
            <p>The score is calculated into four bands.
                <ul class="plain">
                    <li>
                        🟩 <strong>Excellent</strong> - shown if the guess is 85% closer or more.
                    </li>
                    <li>
                        🟨 <strong>Great</strong> - shown if the guess is between 50% and 85% closer or more.
                    </li>
                    <li>
                        🟧 <strong>Good</strong> - shown if the guess is between 20% and 49% closer or more.
                    </li>
                    <li>
                        🟥 <strong>Poor</strong> - keep practicing! This is shown if you are lower than 20%.
                    </li>
                </ul>
            </p>

            <h3>Seeds</h3>
            <p>The game words off a seeding functionality</p>

            <h3>Shuffle</h3>
            <p>Want more Flaggle? Use the shuffle mode to play randomised games. The seed value is shown if you want your friends to give that flag a go, click the share seed to add a link to your clipboard!</p>

            <h3>Inspiration</h3>
            <p>Heavily inspired by the excellent <a href="https://worldle.teuteuf.fr/" target="_blank">Worldle</a>.</p>

            <h3>Thanks</h3>
            <p>Hope you find this enjoyable, thanks for playing<br>
            Created with by John.</p>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        methods: {
            ...mapActions('UIManager', [
                'toggleInfoPanel'
            ])
        }
    }
</script>