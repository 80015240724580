import { saveGuessesData } from "../../lib/guess";

const state = {
    guessHistory: [],
    dailyGamesPlayed: null,
    dailyGamesWon: null,
    dailyGameStreak: null,
    dailySkips: null,
    dailyWinPercentage: null,
    dailyGuessHistory: null,
    dailyAverageDistance: null,
    dailyTotalDistance: null,
    seedGamesPlayed: null,
    seedGamesWon: null,
    seedGameStreak: null,
    seedSkips: null,
    seedWinPercentage: null,
    seedGuessHistory: null,
    seedAverageDistance: null,
    seedTotalDistance: null,
}

const actions = {
    updateStatsBasedOnHistory: ({ commit }) => {
        const dailyGames = state.guessHistory.filter(guess => guess.gameMode === 'daily');
        const dailyGamesPlayed = dailyGames.length;
        const dailyGamesWon = state.guessHistory.filter(guess => guess.gameMode === 'daily' && guess.answerFound === true).length;
        const dailyWinPercentage = dailyGamesWon / dailyGamesPlayed * 100;
        let dailyTotalDistance = 0;
        dailyGames.forEach(guess => dailyTotalDistance += guess.distance);
        const dailyAverageDistance = dailyTotalDistance / dailyGamesPlayed;


        const seedGames = state.guessHistory.filter(guess => guess.gameMode === 'seed');
        const seedGamesPlayed = seedGames.length;
        const seedGamesWon = state.guessHistory.filter(guess => guess.gameMode === 'seed' && guess.answerFound === true).length;
        const seedWinPercentage = seedGamesWon / seedGamesPlayed * 100;
        let seedTotalDistance = 0;
        seedGames.forEach(guess => seedTotalDistance += guess.distance);
        const seedAverageDistance = seedTotalDistance / seedGamesPlayed;

        commit('UPDATE_STATS', {
            dailyGamesPlayed,
            dailyGamesWon,
            dailyWinPercentage,
            dailyTotalDistance,
            dailyAverageDistance,
            seedGamesPlayed,
            seedGamesWon,
            seedWinPercentage,
            seedTotalDistance,
            seedAverageDistance,
        });
    },

    addGuessToHistory: ({ commit }, guess) => {
        commit('ADD_GUESS_TO_HISTORY', guess);
    },

    setGuessHistory: ({ commit }, history) => {
        commit('SET_GUESS_HISTORY', history);
    },
}
const mutations = {
    UPDATE_STATS(state, obj) {
        state.dailyGamesPlayed = obj.dailyGamesPlayed;
        state.dailyGamesWon = obj.dailyGamesWon;
        state.dailyWinPercentage = obj.dailyWinPercentage;
        state.dailyTotalDistance = obj.dailyTotalDistance;
        state.dailyAverageDistance = obj.dailyAverageDistance;
        state.seedGamesPlayed = obj.seedGamesPlayed;
        state.seedGamesWon = obj.seedGamesWon;
        state.seedWinPercentage = obj.seedWinPercentage;
        state.seedTotalDistance = obj.seedTotalDistance;
        state.seedAverageDistance = obj.seedAverageDistance;
    },

    ADD_GUESS_TO_HISTORY(state, guess) {
        state.guessHistory.push(guess);
        saveGuessesData(state.guessHistory);
    },

    SET_GUESS_HISTORY(state, history) {
        state.guessHistory = history;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}