import { locationData } from '../../lib/locations';
import Guess from '../../lib/guess';

const ALLOWED_GUESS_COUNT = 5;

const getters = {
    getCountryObjByNameHoisted: () => {
        return function (args) {
            return getters.getCountryObjByName(args);
        }
    },

    getCountryObjByName: (name) => {
        return locationData.filter(location => {
            return (location.name == name);
        })[0];
    },

    getRandomCountry: () => {
        const totalLocations = locationData.length;
        return locationData[Math.floor(Math.random() * totalLocations - 1)];
    },

    getRandomCountryGuess: () => {
        const newLocation = getters.getRandomCountry();
        const item = new Guess({ 
            name: newLocation.name,
            location: newLocation,
            answer: state.answerFound,
            date: null,
            gameMode: null,
        });
        return item;
    },

    getRandomGuesses: () => {
        return new Array(ALLOWED_GUESS_COUNT - 1).fill(undefined).map(() => getters.getRandomCountryGuess());
    },

    getGuessCount: () => {
        return state.guesses.length;
    },

    isOutOfGuesses: () => {
        return (state.allowedGuessCount - getters.getGuessCount() <= 0);
    },

    isWin: () => {
        return state.guesses.filter(a => a.answerFound).length > 0;
    },

    getRemainingGuesses: () => {
        return state.allowedGuessCount - state.guesses.length;
    },

    getGuessWinIndex: () => {
        return state.guesses.findIndex(a => a.answerFound);
    },

    getHighestPercentage: () => {
        if (state.guesses.length === 0) return 0;

        const clone = [...state.guesses];

        const sorted = clone.sort((a, b) => {
            return b.percentage - a.percentage;
        });

        return sorted[0].percentage;
    },
}

const state = {
    guesses: [],
    answer: null,
    allowedGuessCount: ALLOWED_GUESS_COUNT,
    hardcoreMode: false,
    seedText: null,
    gameMode: null,
    giveUp: false,
}

const actions = {
    setGiveUpGame: ({commit}, toggle) => {
        commit('SET_GIVE_UP', toggle);
    },

    setGameMode: ({ commit }, mode) => {
        commit('SET_GAME_MODE', mode);
    },

    setSeedText: ({ commit }, text) => {
        commit('SET_SEED_TEXT', text);
    },

    setAnswerRandomLocation: ({ commit })  => {
        const location = getters.getRandomCountry();
        commit('UPDATE_ANSWER_LOCATION', location);
    },

    setAnswerLocationByName: ({ commit }, location)  => {
        const locationObj = getters.getCountryObjByName(location);
        commit('UPDATE_ANSWER_LOCATION', locationObj);
    },

    addNewGuess: ({ commit }, newGuess) => {
        commit('ADD_NEW_GUESS', newGuess);
    },

    setTodayGuesses: ({commit }, guesses) => {
        commit('SET_TODAY_GUESSES', guesses);
    },

    clearAllGuesses: ({ commit }) => {
        commit('clearGuesses');
    },

    regenGuesses: ({ commit }) => {
        commit('regenGuesses');
    },

    setHardcoreMode: ({ commit }) => {
        commit('updateHardcoreMode');
    },
}
const mutations = {
    SET_GIVE_UP(state, toggle) {
        state.giveUp = toggle;
    },

    SET_GAME_MODE(state, mode) {
        state.gameMode = mode;
    },

    SET_SEED_TEXT(state, text) {
        state.seedText = text;
    },

    UPDATE_ANSWER_LOCATION(state, data) {
        state.answer = data;
    },

    ADD_NEW_GUESS(state, newGuess) {
        state.guesses.push(newGuess);
    },

    SET_TODAY_GUESSES(state, guesses) {
        state.guesses = guesses;
    },

    clearGuesses(state) {
        state.guesses = [];
    },

    regenGuesses(state) {
        state.guesses = getters.getRandomGuesses();
    },

    updateHardcoreMode(state) {
        state.hardcoreMode = !state.hardcoreMode;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}