var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu" }, [
    _c("strong", [_vm._v("Debug Menu")]),
    _vm._v(" "),
    _c("p", { staticStyle: { "margin-bottom": "1rem" } }, [
      _vm._v(" \n        GameMode: " + _vm._s(_vm.gameMode)),
      _c("br"),
      _vm._v("\n        Guess Count: " + _vm._s(_vm.getGuessCount)),
      _c("br"),
      _vm._v(
        "\n        Remaining Guesses: " +
          _vm._s(_vm.allowedGuessCount - _vm.getGuessCount)
      ),
      _c("br"),
      _vm._v("\n        Given Up: " + _vm._s(_vm.giveUp)),
      _c("br"),
      _vm._v(" "),
      _c("label", [
        _vm._v("\n            Hardcore mode "),
        _c("input", {
          staticStyle: { width: "auto" },
          attrs: { type: "checkbox" },
          on: {
            change: function ($event) {
              return _vm.updateHardcoreMode()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.setAnswerRandomLocation()
          },
        },
      },
      [_vm._v("New Location")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.clearAllGuesses()
          },
        },
      },
      [_vm._v("Clear guesses")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.regenGuesses()
          },
        },
      },
      [_vm._v("Regen guesses")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.clearStorage()
          },
        },
      },
      [_vm._v("Clear Storage")]
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("pre", { staticClass: "answer" }, [
      _vm._v("Location: " + _vm._s(_vm.answer)),
    ]),
    _vm._v(" "),
    _c("label", [_vm._v("Force Country:")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.forceCountryName,
          expression: "forceCountryName",
        },
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.forceCountryName },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.forceCountryName = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.forceCountry()
          },
        },
      },
      [_vm._v("Force Country")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }