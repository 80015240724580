var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guess", attrs: { "aria-label": _vm.guessAriaLabel } },
    [
      _vm.hasGuessed && _vm.guess
        ? [
            _c("span", { staticClass: "guess__country" }, [
              _vm._v(_vm._s(_vm.guess.name)),
            ]),
            _vm._v(" "),
            _c("div", [
              !_vm.guess.answerFound
                ? _c(
                    "span",
                    { attrs: { "aria-label": _vm.directionAriaLabel } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("directionEmoji")(_vm.guess.direction))
                      ),
                    ]
                  )
                : _c("span", [_vm._v("✨")]),
              _vm._v(" "),
              _c("span", { staticClass: "guess__distance" }, [
                _vm._v(
                  _vm._s(_vm._f("displayDistance")(_vm.guess.distance)) + "km "
                ),
                _c("span", { staticClass: "sr-only" }, [_vm._v("away")]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "guess__percentage",
                  class: _vm.getPercentageClass,
                  attrs: { "aria-label": "Guess rating" },
                },
                [_vm._v(_vm._s(_vm.guess.percentage) + "%")]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }