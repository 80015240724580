var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flag-placeholder" }, [
    _c("div", {
      staticClass: "flag is-flag",
      class: { "is-hardcore": _vm.hardcoreMode },
      style: {
        backgroundImage: "url(" + _vm.flagUrl + ")",
        backgroundSize: "contain",
      },
      attrs: { "aria-label": "Country flag to guess" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }