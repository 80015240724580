var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "modal__header" }, [
        _c("h2", [_vm._v("Stats")]),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { "aria-label": "Close modal" },
            on: {
              click: function ($event) {
                return _vm.toggleStatsPanel(false)
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 512 512",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "statGroup" }, [
        _c("h3", [_vm._v("Daily")]),
        _vm._v(" "),
        _c("div", { staticClass: "statGroup__inner" }, [
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(_vm._s(_vm.dailyGamesPlayed)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Games Played")]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(_vm._s(_vm.dailyGamesWon)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Games Won")]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(
                _vm._s(_vm._f("noDecimalPlaces")(_vm.dailyWinPercentage)) + "%"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Win")]),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(
                _vm._s(_vm._f("noDecimalPlaces")(_vm.dailyAverageDistance))
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [
              _vm._v("Average Distance (km)"),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "statGroup" }, [
        _c("h3", [_vm._v("Seeded")]),
        _vm._v(" "),
        _c("div", { staticClass: "statGroup__inner" }, [
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(_vm._s(_vm.seedGamesPlayed)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Games Played")]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(_vm._s(_vm.seedGamesWon)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Games Won")]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(
                _vm._s(_vm._f("noDecimalPlaces")(_vm.seedWinPercentage)) + "%"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [_vm._v("Win")]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _c("span", { staticClass: "stat__value" }, [
              _vm._v(
                _vm._s(_vm._f("noDecimalPlaces")(_vm.seedAverageDistance))
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stat__name" }, [
              _vm._v("Average Distance (km)"),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }