import seed from 'seed-random';
import { locationData } from '../lib/locations';
import { getTodaysDateString } from '../lib/helpers';

export const getTodaysLocation = (daySeed) => {
    return locationData[Math.floor(locationData.length * daySeed)];
}

export const generateDailySeedText = (override = '') => {
    return seed(getTodaysDateString() + override)();
}

export const generateSeedText = (text) => {
    return seed(text)();
}