import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import GameManager from './modules/GameManager';
import UIManager from './modules/UIManager';
import StatsManager from './modules/StatsManager';

const store = new Vuex.Store({
    modules: {
        GameManager,
        UIManager,
        StatsManager,
    },
});

export default store;