<template>
	<div class='modal'>
		<div class='container'>
            <div class='modal__header'>
			    <h2>Stats</h2>
                <button @click='toggleStatsPanel(false)' aria-label="Close modal">
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z'/></svg>
                </button>
            </div>

            <div class='statGroup'>
                <h3>Daily</h3>
                <div class='statGroup__inner'>
                    <h4>
                        <span class='stat__value'>{{dailyGamesPlayed}}</span>
                        <span class='stat__name'>Games Played</span>
                    </h4>
                    <h4>
                        <span class='stat__value'>{{dailyGamesWon}}</span>
                        <span class='stat__name'>Games Won</span>
                    </h4>
                    <h4>
                        <span class='stat__value'>{{dailyWinPercentage | noDecimalPlaces}}%</span>
                        <span class='stat__name'>Win</span>
                    </h4>
                    <br>
                    <h4>
                        <span class='stat__value'>{{dailyAverageDistance | noDecimalPlaces}}</span>
                        <span class='stat__name'>Average Distance (km)</span>
                    </h4>
                </div>
            </div>
            <div class='statGroup'>
                <h3>Seeded</h3>
                <div class='statGroup__inner'>
                    <h4>
                        <span class='stat__value'>{{seedGamesPlayed}}</span>
                        <span class='stat__name'>Games Played</span>
                    </h4>    
                    <h4>
                        <span class='stat__value'>{{seedGamesWon}}</span>
                        <span class='stat__name'>Games Won</span>
                    </h4>    
                    <h4>
                        <span class='stat__value'>{{seedWinPercentage | noDecimalPlaces}}%</span>
                        <span class='stat__name'>Win</span>
                    </h4>
                    <h4>
                        <span class='stat__value'>{{seedAverageDistance | noDecimalPlaces}}</span>
                        <span class='stat__name'>Average Distance (km)</span>
                    </h4>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
        computed: {
            ...mapState('StatsManager', [
                'dailyGamesPlayed',
                'dailyGamesWon',
                'dailyWinPercentage',
                'dailyTotalDistance',
                'dailyAverageDistance',
                'seedGamesPlayed',
                'seedGamesWon',
                'seedWinPercentage',
                'seedTotalDistance',
                'seedAverageDistance',
            ])
        },
		methods: {
			...mapActions('UIManager', [
				'toggleStatsPanel'
			])
		},

        filters: {
            noDecimalPlaces(val) {
                return Number(val).toFixed(0);
            },

            twoDecimalPlaces(val) {
                return Number(val).toFixed(2);
            },
        }
	}

</script>

<style lang='scss'>
    .statGroup {
        margin-bottom: 30px;

        @media (min-width: 768px) {
            margin-bottom: 60px;
        }

        &__inner {
            display: flex;
            justify-content: space-evenly;
        }
        
        h3 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 15px;
            color: #d3d3d3;
        }

        h4 {
            flex: 1;
            text-align: center;
        }
    }

    .stat {
        &__value {
            display: block;
            font-size: 30px;
            text-align: center;
        }

        &__name {
            color: #d3d3d3;
        }
    }
</style>
